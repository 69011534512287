<template>
  <el-main>
    <div class="bigtop">
      请先在 推广>CPS分销>分销基本设置>分销设置 中设置分销员申请条件。
      <span class="onceSet" @click="$router.push('/extension/distribution/basicSet')">立即设置</span>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
      <el-form-item label="团长自动成为分销员：" prop="auto_distribution">
        <el-switch v-model="ruleForm.auto_distribution" :active-value="1" :inactive-value="0"></el-switch>
        <span class="tips ml20" style="line-height: 20px">开启后，后台添加的团长,会按照分销设置中的成为分销员条件，自动成为分销员，享受分销员权益</span>
      </el-form-item>
      <el-form-item label="店铺资质：">
        <span class="tips" style="line-height: 20px">请在下面添加店铺营业执照，门头照等信息，添加后，显示在店铺首页，搜索框后面</span>
        <RichText :richTxt="ruleForm.qualifications" @soninfo="val => (ruleForm.qualifications = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      ruleForm: {
        auto_distribution: 0,
        qualifications: '',
      },
      rules: {
        auto_distribution: [{ required: true }],
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$axios.post(this.$api.retail.getHeadInfo).then(res => {
        if (res.code == 0) {
          for (const key in this.ruleForm) {
            this.ruleForm[key] = res.result[key];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.auto_distribution && !this.ruleForm.qualifications) {
            this.$message.warning('请上传店铺资质');
            return;
          }
          this.$axios.post(this.$api.retail.headerSet, this.ruleForm).then(res => {
            if (res.code == 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 100px;
  .bigtop {
    height: 64px;
    background: #ebf2ff;
    border: 1px solid #81aeff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 18px;
    .onceSet {
      cursor: pointer;
      color: #3566da;
      text-decoration: underline;
      user-select: none;
    }
  }
  .tips {
    font-size: 12px;
    color: #989898;
  }
  .ml20 {
    margin-left: 20px;
  }
}
</style>
